import React, { useEffect, useState } from "react";
import { Styled } from "./diagram5.styled";

import AppointmentRequestsByCustomerNumber from "../../LeftSide/AppointmentRequestsByCustomerNumber";
import Diagram4Chart from "../../Charts/diagram4Chart/diagram4Chart.component";
import {
  convertDataForDiagram4,
  convertDataForDiagram5,
  convertDataToDayStepDiagram4_5_chart1,
  convertDataToDayStepDiagram4_5_chart2,
  DeleteByIndex,
  DeleteRow,
  getDataForSelectDiagram4,
  getKeysDiagram4,
  getSum1YForDiagram4,
  getSum1YForDiagram5,
  InsertNewValue,
  InsertRow,
} from "../../../middleware/math";
import {
  arrButtonsName,
  chartDiagram4Option_chart1,
  chartDiagram4Option_chart2,
  defaultSettingDiagram5,
  headerDiagram5,
} from "../../../const/const";

const Diagram5 = ({ data }) => {
  const [buttonIndex, setButtonIndex] = useState(0);
  const [buttonDateIndex, setButtonDateIndex] = useState({ 0: 3, 1: 3 });

  const hanldeChangeIndex = (index) => {
    setButtonIndex(index);
  };

  const hanldeChangeDateIndex = (index) => {
    setButtonDateIndex((prev) => ({ ...prev, [buttonIndex]: index }));
  };

  const [select1Element, setSelect1Element] = useState({
    0: getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[0]]])[0],
  });

  const handleSetSelect1Element = (value) => {
    setSelect1Element((prev) => ({ ...prev, [buttonIndex]: value }));
  };

  const [select2Element, setSelect2Element] = useState({
    0: getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[0]]])[0],
  });

  const handleSetSelect2Element = (value) => {
    setSelect2Element((prev) => ({ ...prev, [buttonIndex]: value }));
  };

  const [series1, setSeries1] = useState([]);
  const [options1State, setOptions1State] = useState({
    ...chartDiagram4Option_chart1,
    colors: defaultSettingDiagram5.color1,

    xaxis: {
      categories: [
        select1Element[buttonIndex] ? select1Element[buttonIndex] : "",
      ],
    },
  });

  const [series2, setSeries2] = useState([]);

  const [categories2, setCategories2] = useState(
    getKeysDiagram4(
      data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
    )
  );
  const [options2State, setOptions2State] = useState(
    chartDiagram4Option_chart2(
      defaultSettingDiagram5.color2,
      categories2,
      defaultSettingDiagram5.heightLegend
    )
  );

  const handleRemoveRow2 = (index) => {
    const resRows = DeleteRow(series2, index);
    setSeries2(resRows);

    const newCategories2 = DeleteByIndex(categories2, index);
    setCategories2(newCategories2);
    setOptions2State(
      chartDiagram4Option_chart2(
        defaultSettingDiagram5.color2,
        newCategories2,
        defaultSettingDiagram5.heightLegend
      )
    );
  };

  const handleAddRow2 = (selectElement2) => {
    const resRows = InsertRow(
      series2,
      Object.values(
        data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]][
          selectElement2
        ]
      )
    );
    setSeries2(resRows);

    const newCategories2 = InsertNewValue(categories2, [selectElement2]);
    setCategories2(newCategories2);
    setOptions2State(
      chartDiagram4Option_chart2(
        defaultSettingDiagram5.color2,
        newCategories2,
        defaultSettingDiagram5.heightLegend
      )
    );
  };

  useEffect(() => {
    if (
      data &&
      data[buttonIndex] &&
      arrButtonsName[buttonDateIndex[buttonIndex]] &&
      select1Element[buttonIndex] !== undefined
    ) {
      const resSelect1Element = getKeysDiagram4(
        data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
      );

      let select1ElementChanged = select1Element[buttonIndex];

      if (resSelect1Element.length === 0) {
        handleSetSelect1Element(undefined);
        select1ElementChanged = undefined;
        handleSetSelect2Element(undefined);
      }
      if (
        resSelect1Element.length > 0 &&
        select1Element !== undefined &&
        !resSelect1Element.some((item) => item === select1Element[buttonIndex])
      ) {
        handleSetSelect1Element(resSelect1Element[0]);
        select1ElementChanged = resSelect1Element[0];
      }
      if (resSelect1Element.length > 0 && select1Element === undefined) {
        handleSetSelect1Element(resSelect1Element[0]);
        select1ElementChanged = resSelect1Element[0];
      }

      setSeries1(
        convertDataForDiagram5(
          data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]],
          select1ElementChanged
        ).chart1.series
      );

      setOptions1State({
        ...chartDiagram4Option_chart1,
        colors: defaultSettingDiagram5.color1,

        xaxis: {
          categories: [
            select1Element[buttonIndex] ? select1Element[buttonIndex] : "",
          ],
        },
      });
      setSeries2(
        convertDataForDiagram5(
          data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]],
          select1ElementChanged
        ).chart2.series
      );
      setOptions2State(
        chartDiagram4Option_chart2(
          defaultSettingDiagram5.color2,
          getKeysDiagram4(
            data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
          ),
          defaultSettingDiagram5.heightLegend
        )
      );
      setCategories2(
        getKeysDiagram4(
          data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
        )
      );
    }
  }, [
    select1Element[buttonIndex],
    buttonIndex,
    buttonDateIndex[buttonIndex],
    data,
  ]);

  return (
    <Styled.MainBox>
      <Styled.LeftBox>
        {headerDiagram5.map((item, index) => (
          <AppointmentRequestsByCustomerNumber
            key={index}
            color={buttonIndex === index ? "yellow" : "white"}
            header={item}
            value={getSum1YForDiagram5(
              data[index][arrButtonsName[buttonDateIndex[index]]]
            )}
            hanldeChangeIndex={hanldeChangeIndex}
            index={index}
            selectPeriod={arrButtonsName[buttonDateIndex[index]]}
          />
        ))}
      </Styled.LeftBox>
      <Styled.RightBox>
        <Diagram4Chart
          header1="Terminanfragen nach Disponenten ID"
          series1={series1}
          options1State={options1State}
          selectDataArr1={getDataForSelectDiagram4(
            data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
          )}
          select1Element={select1Element[buttonIndex]}
          handleSetSelect1Element={handleSetSelect1Element}
          header2="Vergleichsdisponent"
          series2={series2}
          categories2={categories2}
          options2State={options2State}
          selectDataArr2={getDataForSelectDiagram4(
            data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
          )}
          select2Element={select2Element[buttonIndex]}
          handleSetSelect2Element={handleSetSelect2Element}
          handleRemoveRow2={handleRemoveRow2}
          handleAddRow2={handleAddRow2}
          buttonIndex={buttonIndex}
          buttonDateIndex={buttonDateIndex[buttonIndex]}
          hanldeChangeDateIndex={hanldeChangeDateIndex}
          defaultSettingDiagram={defaultSettingDiagram5}
        />
      </Styled.RightBox>
    </Styled.MainBox>
  );
};

export default Diagram5;
