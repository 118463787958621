import React from "react";

import { Styled } from "./AppointmentRequestsByCustomerNumber.styled";

const AppointmentRequestsByCustomerNumber = ({
  color,
  header,
  description,
  value,
  hanldeChangeIndex,
  index,
  selectPeriod,
}) => {
  return (
    <Styled.MainBox
      $color={color === "yellow"}
      onClick={() => hanldeChangeIndex(index)}
    >
      <Styled.InsideBox>
        <Styled.HeaderBox $color={color === "yellow"}>
          {header}
        </Styled.HeaderBox>
        <Styled.GroupBox>
          <Styled.GroupItemLeft>
            <Styled.DescriptionBox $color={color === "yellow"}>
              {description}
            </Styled.DescriptionBox>
            <Styled.ItemBox>
              <Styled.ItemBoxIcon1>{selectPeriod}</Styled.ItemBoxIcon1>
              <Styled.ItemValue $color={color === "yellow"}>
                {value}
              </Styled.ItemValue>
            </Styled.ItemBox>
          </Styled.GroupItemLeft>
          <Styled.GroupItemRight>
            <Styled.ItemBoxIcon2
              color={color === "yellow" ? "#EFF2F7" : "#ccc"}
            ></Styled.ItemBoxIcon2>
          </Styled.GroupItemRight>
        </Styled.GroupBox>
      </Styled.InsideBox>
    </Styled.MainBox>
  );
};

export default AppointmentRequestsByCustomerNumber;
