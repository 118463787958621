import React, { useContext } from "react";
import { DataContext } from "../../../context/context";

import { Styled } from "./ResponseTimeTimeProgress.styled";

const ResponseTimeTimeProgress = ({ value, description }) => {
  const { state } = useContext(DataContext);

  return (
    <Styled.MainBox>
      <Styled.ItemTimeBoxIcon>
        <Styled.ItemTimeIcon>Time</Styled.ItemTimeIcon>
      </Styled.ItemTimeBoxIcon>
      <Styled.ItemValueGroup>
        <Styled.ItemValueIconMark>⌀</Styled.ItemValueIconMark>
        <Styled.ItemValue>{value}</Styled.ItemValue>
      </Styled.ItemValueGroup>
      <Styled.ItemBoxDescription>{description}</Styled.ItemBoxDescription>
    </Styled.MainBox>
  );
};

export default ResponseTimeTimeProgress;
