import React, { useState } from "react";
import { Styled } from "./diagram1.styled";

import ReductionsItem from "../../LeftSide/ReductionsItem";
import Diagram1Chart from "../../Charts/diagram1Chart/diagram1Chart.component";
import { getSum } from "../../../middleware/math";
import { arrButtonsName, headerDiagram1 } from "../../../const/const";

const Diagram1 = ({ data }) => {
  const [buttonIndex, setButtonIndex] = useState(0);
  const [buttonDateIndex, setButtonDateIndex] = useState({ 0: 0, 1: 0, 2: 0 });

  const hanldeChangeIndex = (index) => {
    setButtonIndex(index);
  };

  const hanldeChangeDateIndex = (index) => {
    setButtonDateIndex((prev) => ({ ...prev, [buttonIndex]: index }));
  };
  return (
    <Styled.MainBox>
      <Styled.LeftBox>
        {headerDiagram1.map((item, index) => (
          <ReductionsItem
            key={index}
            color={buttonIndex === index ? "yellow" : "white"}
            header={item.header}
            description={item.description}
            value={getSum(
              data[index],
              arrButtonsName[buttonDateIndex[index]],
              "current"
            )}
            hanldeChangeIndex={hanldeChangeIndex}
            index={index}
            selectPeriod={arrButtonsName[buttonDateIndex[index]]}
          />
        ))}
      </Styled.LeftBox>
      <Styled.RightBox>
        <Diagram1Chart
          buttonIndex={buttonIndex}
          data={data}
          buttonDateIndex={buttonDateIndex}
          hanldeChangeDateIndex={hanldeChangeDateIndex}
        />
      </Styled.RightBox>
    </Styled.MainBox>
  );
};

export default Diagram1;
