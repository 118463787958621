import React, { useState } from "react";
import { Styled } from "./diagram3.styled";

import NumberOfDeliveryDateRequests from "../../LeftSide/NumberOfDeliveryDateRequests";
import Diagram3Chart from "../../Charts/diagram3Chart/diagram3Chart.component";
import { arrButtonsName, headerDiagram3 } from "../../../const/const";
import { getSumDiagram3 } from "../../../middleware/math";

const Diagram3 = ({ data }) => {
  const [buttonIndex, setButtonIndex] = useState(0);
  const [buttonDateIndex, setButtonDateIndex] = useState({ 0: 0, 1: 0 });

  const hanldeChangeIndex = (index) => {
    setButtonIndex(index);
  };

  const hanldeChangeDateIndex = (index) => {
    setButtonDateIndex((prev) => ({ ...prev, [buttonIndex]: index }));
  };

  return (
    <Styled.MainBox>
      <Styled.LeftBox>
        {headerDiagram3.map((item, index) => (
          <NumberOfDeliveryDateRequests
            key={index}
            color={buttonIndex === index ? "yellow" : "white"}
            header={item}
            value={getSumDiagram3(
              data[index][arrButtonsName[buttonDateIndex[index]]]
            )}
            hanldeChangeIndex={hanldeChangeIndex}
            index={index}
            selectPeriod={arrButtonsName[buttonDateIndex[index]]}
          />
        ))}
      </Styled.LeftBox>
      <Styled.RightBox>
        <Diagram3Chart
          buttonIndex={buttonIndex}
          data={data}
          buttonDateIndex={buttonDateIndex}
          hanldeChangeDateIndex={hanldeChangeDateIndex}
        />
      </Styled.RightBox>
    </Styled.MainBox>
  );
};

export default Diagram3;
