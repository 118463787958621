import React, { useEffect, useState } from "react";
import { Styled } from "./diagram4.styled";

import AppointmentRequestsByCustomerNumber from "../../LeftSide/AppointmentRequestsByCustomerNumber";
import Diagram4Chart from "../../Charts/diagram4Chart/diagram4Chart.component";
import {
  convertDataForDiagram4,
  DeleteByIndex,
  DeleteRow,
  getDataForSelectDiagram4,
  getKeysDiagram4,
  getSum1YForDiagram4,
  InsertNewValue,
  InsertRow,
} from "../../../middleware/math";
import {
  arrButtonsName,
  chartDiagram4Option_chart1,
  chartDiagram4Option_chart2,
  defaultSettingDiagram4,
  headerDiagram4,
} from "../../../const/const";

const Diagram4 = ({ data }) => {
  const [buttonIndex, setButtonIndex] = useState(0);
  const [buttonDateIndex, setButtonDateIndex] = useState({ 0: 0 });

  const hanldeChangeDateIndex = (index) => {
    setButtonDateIndex({ 0: index });
  };

  const [select1Element, setSelect1Element] = useState(
    getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[buttonIndex]]])[0]
  );

  const handleSetSelect1Element = (value) => {
    setSelect1Element(value);
  };

  const [select2Element, setSelect2Element] = useState(
    getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[buttonIndex]]])[0]
  );

  const handleSetSelect2Element = (value) => {
    setSelect2Element(value);
  };

  // chart1
  const [series1State, setSeries1State] = useState([]);

  const [options1State, setOptions1State] = useState({
    ...chartDiagram4Option_chart1,
    colors: defaultSettingDiagram4.color1,

    xaxis: {
      categories: [select1Element ? select1Element : ""],
    },
  });

  const [series2, setSeries2] = useState([]);

  const [categories2, setCategories2] = useState(
    getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[buttonIndex]]])
  );
  const [options2State, setOptions2State] = useState(
    chartDiagram4Option_chart2(
      defaultSettingDiagram4.color2,
      categories2,
      defaultSettingDiagram4.heightLegend
    )
  );

  const handleRemoveRow2 = (index) => {
    const resRows = DeleteRow(series2, index);
    setSeries2(resRows);

    const newCategories2 = DeleteByIndex(categories2, index);
    setCategories2(newCategories2);
    setOptions2State(
      chartDiagram4Option_chart2(
        defaultSettingDiagram4.color2,
        newCategories2,
        defaultSettingDiagram4.heightLegend
      )
    );
  };

  const handleAddRow2 = (selectElement2) => {
    const resRows = InsertRow(
      series2,
      Object.values(
        data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]][
          selectElement2
        ]
      )
    );
    setSeries2(resRows);

    const newCategories2 = InsertNewValue(categories2, [selectElement2]);
    setCategories2(newCategories2);
    setOptions2State(
      chartDiagram4Option_chart2(
        defaultSettingDiagram4.color2,
        newCategories2,
        defaultSettingDiagram4.heightLegend
      )
    );
  };

  useEffect(() => {
    const resSelect1Element = getKeysDiagram4(
      data[0][arrButtonsName[buttonDateIndex[buttonIndex]]]
    );

    let select1ElementChanged = select1Element;

    if (resSelect1Element.length === 0) {
      setSelect1Element(undefined);
      select1ElementChanged = undefined;
      handleSetSelect2Element(undefined);
    }
    if (
      resSelect1Element.length > 0 &&
      select1Element !== undefined &&
      !resSelect1Element.some((item) => item === select1Element)
    ) {
      setSelect1Element(resSelect1Element[0]);
      select1ElementChanged = resSelect1Element[0];
    }
    if (resSelect1Element.length > 0 && select1Element === undefined) {
      setSelect1Element(resSelect1Element[0]);
      select1ElementChanged = resSelect1Element[0];
    }

    setSeries1State(
      convertDataForDiagram4(
        data[0][arrButtonsName[buttonDateIndex[0]]],
        select1ElementChanged
      ).chart1.series
    );
    setOptions1State({
      ...chartDiagram4Option_chart1,
      colors: defaultSettingDiagram4.color1,

      xaxis: {
        categories: [select1Element ? select1Element : ""],
      },
    });

    setSeries2(
      convertDataForDiagram4(
        data[0][arrButtonsName[buttonDateIndex[0]]],
        select1Element
      ).chart2.series
    );

    setOptions2State(
      chartDiagram4Option_chart2(
        defaultSettingDiagram4.color2,

        getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[0]]]),
        defaultSettingDiagram4.heightLegend
      )
    );

    setCategories2(
      getKeysDiagram4(data[0][arrButtonsName[buttonDateIndex[buttonIndex]]])
    );
  }, [select1Element, buttonDateIndex[0]]);

  return (
    <Styled.MainBox>
      <Styled.LeftBox>
        {headerDiagram4.map((item, index) => (
          <AppointmentRequestsByCustomerNumber
            key={index}
            color={buttonIndex === index ? "yellow" : "white"}
            header={item}
            value={getSum1YForDiagram4(
              data[index][arrButtonsName[buttonDateIndex[index]]]
            )}
            hanldeChangeIndex={0}
            index={index}
            selectPeriod={arrButtonsName[buttonDateIndex[index]]}
          />
        ))}
      </Styled.LeftBox>
      <Styled.RightBox>
        <Diagram4Chart
          header1="Terminanfragen nach Kundennummer"
          series1={series1State}
          options1State={options1State}
          selectDataArr1={getDataForSelectDiagram4(
            data[0][arrButtonsName[buttonDateIndex[buttonIndex]]]
          )}
          select1Element={select1Element}
          handleSetSelect1Element={handleSetSelect1Element}
          header2="Vergleichskunde"
          series2={series2}
          options2State={options2State}
          categories2={categories2}
          selectDataArr2={getDataForSelectDiagram4(
            data[0][arrButtonsName[buttonDateIndex[buttonIndex]]]
          )}
          select2Element={select2Element}
          handleSetSelect2Element={handleSetSelect2Element}
          handleRemoveRow2={handleRemoveRow2}
          handleAddRow2={handleAddRow2}
          buttonIndex={0}
          hanldeChangeDateIndex={hanldeChangeDateIndex}
          buttonDateIndex={buttonDateIndex[0]}
          defaultSettingDiagram={defaultSettingDiagram4}
        />
      </Styled.RightBox>
    </Styled.MainBox>
  );
};

export default Diagram4;
