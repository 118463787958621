import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;
  padding: 20px;

  ${({ $color }) =>
    $color &&
    `
    background: #f1b44c;
  }

    `}
  margin-bottom: 17px;
  border-radius: 4px;

  width: 430px;
  cursor: pointer;
`;

export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  color: #495057;

  ${({ $color }) =>
    $color &&
    `
    color: #fff;
}

  `}
`;

export const GroupBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 6px;
`;

export const GroupItemLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #74788d;

  ${({ $color }) =>
    $color &&
    `
    color: #fff;
  }
  `}
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 6px;
`;

export const ItemBoxIcon1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 11.3px;
  line-height: 17px;
  color: #fff;

  min-height: 28px;
  min-width: 28px;

  height: 28px;
  width: 28px;

  background: rgba(224, 229, 236, 0.31);
  border: 1px solid rgba(224, 229, 236, 0.31);
  border-radius: 4px;

  margin-right: 12px;
  background: ${(props) => props.color};
`;

export const ItemValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 19.5px;
  line-height: 29px;

  width: 100%;

  color: #495057;

  ${({ $color }) =>
    $color &&
    `
  color: #fff;
  }
  `}
  margin-right:12px;
`;

export const GroupItemRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
`;

export const ItemBoxIcon2 = styled.img`
  height: 46;
  width: 46;
`;

export const ItemBoxIcon2fast = styled.img`
  height: 46;
  width: 46;
`;

export const Styled = {
  MainBox,
  InsideBox,
  HeaderBox,
  DescriptionBox,
  GroupBox,
  GroupItemLeft,
  ItemBox,
  ItemBoxIcon1,
  ItemValue,
  GroupItemRight,
  ItemBoxIcon2,
  ItemBoxIcon2fast,
};
