import styled from "styled-components";
import { ReactComponent as green_arrow } from "../../../images/green_arrow.svg";
import { ReactComponent as red_arrow } from "../../../images/red_arrow.svg";
import { ReactComponent as stopWatch } from "../../../images/stopWatch.svg";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;
  padding: 20px;

  ${({ $color }) =>
    $color &&
    `
    background: #f1b44c;
  }

    `}
  margin-bottom: 17px;
  border-radius: 4px;

  min-width: 200px;
  max-width: 320px;
`;

export const ItemTimeBoxIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ItemTimeIcon = styled(stopWatch)``;

export const ItemValueGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ItemValueIconMark = styled.div`
  display: flex;
  flex-direction: column;

  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #495057;

  padding-bottom: 6px;
  margin-right: 7px;
`;

export const ItemValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 19.5px;
  line-height: 29px;

  color: #495057;

  ${({ $color }) =>
    $color &&
    `
  color: #fff;
  }
  `}
`;

export const ItemBoxGroupValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ItemBoxValueNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #74788d;
`;

export const ItemBoxValuePercent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #74788d;
  margin-left: 4px;
  margin-right: 9px;
`;

export const ItemBoxValueArrow = styled.div``;

export const ItemValueArrowUp = styled(green_arrow)`
  width: 8px;
  height: 8px;
`;

export const ItemValueArrowDown = styled(red_arrow)`
  width: 8px;
  height: 8px;
`;

export const ItemBoxDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;

  color: #74788d;
`;

export const Styled = {
  MainBox,
  ItemTimeBoxIcon,
  ItemTimeIcon,
  ItemValueGroup,
  ItemValueIconMark,
  ItemValue,
  ItemBoxGroupValue,
  ItemBoxValueNumber,
  ItemBoxValuePercent,
  ItemBoxValueArrow,
  ItemValueArrowUp,
  ItemValueArrowDown,
  ItemBoxDescription,
};
