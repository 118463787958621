import styled from "styled-components";
import { ReactComponent as Setting } from "../../images/ButtonLicenseIcon.svg";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f8fb;
`;

export const HeaderBox = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 34px;
`;

export const HeaderBoxItemLeft = styled.div`
  display: flex;
  height: 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-transform: uppercase;

  color: #495057;
`;

export const HeaderBoxItemRight = styled.div`
  display: flex;

  height: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #495057;
`;

export const Styled = {
  MainBox,
  HeaderBox,
  HeaderBoxItemLeft,
  HeaderBoxItemRight,
};
