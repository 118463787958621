import React from 'react';
import { Styled } from './footer.styled';
import { getYear } from '../../middleware/math'

const Footer = () => (

    <Styled.MainBox>
        <Styled.BoxYear>
        {getYear()} © Susi & James. 
        </Styled.BoxYear>
    </Styled.MainBox>
);

export default Footer;