import { Select } from "antd";
import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  padding: 18px;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #495057;

  margin-right: 8px;
`;

export const HeaderControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GroupContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
`;

export const GroupNameSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectStyled = styled(Select)``;

export const Styled = {
  MainBox,
  HeaderBox,
  HeaderName,
  HeaderControl,
  GroupContent,
  GroupNameSelect,
  SelectStyled,
};
