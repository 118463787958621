import React, { useState } from "react";
import { arrButtonsName } from "../../const/const";
import { Styled } from "./ChartDateButtons.styled";

const ChartDateButtons = ({ buttonDateIndex, hanldeChangeDateIndex }) => {
  return (
    <Styled.MainBox>
      {arrButtonsName.map((item, index) => (
        <Styled.ButtonBox
          key={index}
          backgroundColor={buttonDateIndex === index ? "#F1B44C" : "#eff2f7"}
          color={buttonDateIndex === index ? "#fff" : "#000"}
          onClick={() => hanldeChangeDateIndex(index)}
        >
          {item}
        </Styled.ButtonBox>
      ))}
    </Styled.MainBox>
  );
};

export default ChartDateButtons;
