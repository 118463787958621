import React from "react";

const menuItems = [
  {
    title: "users",
    textTranslationHandle: "menu.users",
    path: "users",
    element: "UsersView", // <UsersView />
  },
  {
    title: "scenarios",
    textTranslationHandle: "menu.scenarios",
    path: "scenarios",
    element: "ScenariosView", // <ScenariosView />
  },
  {
    title: "export",
    textTranslationHandle: "menu.exportData",
    path: "export",
    element: "ExportView", // <ExportView />
  },
];

export default menuItems;
