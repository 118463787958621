import styled from "styled-components";
import { ReactComponent as Setting } from "../../images/ButtonLicenseIcon.svg";

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 27px;
  width: 35px;

  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  border: 1px solid #eff2f7;
  border-radius: 4px;

  cursor: pointer;
  margin-left: 20px;
`;

export const Styled = {
  MainBox,
  ButtonBox,
};
