import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// language detector will automatically detect the user's language
// currently commented but could be useful in future
// import LanguageDetector from 'i18next-browser-languagedetector'; 
import Backend from 'i18next-http-backend';

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(
      Backend, 
      {
        loadPath: 'public/locales/{{lng}}/{{ns}}.json',
        addPath: ' /public/locales/{{lng}}/{{ns}}.json',
      }
    )
  // language detector will automatically detect the user's language
  // currently commented but could be useful in future
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
  });

export default i18n;