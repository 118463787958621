import React, { useEffect, useState } from "react";
import { Styled } from "./diagram3Chart.styled";
import ReactApexChart from "react-apexcharts";
import ChartDateButtons from "../../../common/ChartDateButtons";
import { convertDataForDiagram3 } from "../../../middleware/math";
import {
  arrButtonsName,
  chartDiagram3Option,
  chartDiagram3Option_chart2,
} from "../../../const/const";

const Diagram3Chart = ({
  buttonIndex,
  data,
  buttonDateIndex,
  hanldeChangeDateIndex,
}) => {
  const [series1State, setSeries1State] = useState([]);
  const [options1State, setOptions1State] = useState({
    ...chartDiagram3Option,
    xaxis: {
      categories: [],
    },
  });

  const [series2State, setSeries2State] = useState([]);
  const [options2State, setOptions2State] = useState({
    ...chartDiagram3Option_chart2,
    xaxis: {
      categories: [],
    },
  });

  useEffect(() => {
    if (
      data &&
      data[buttonIndex] &&
      buttonDateIndex[buttonIndex] !== undefined
    ) {
      const chartData = convertDataForDiagram3(
        data[buttonIndex][arrButtonsName[buttonDateIndex[buttonIndex]]]
      );

      setOptions1State({
        ...chartDiagram3Option,
        xaxis: {
          categories: chartData.chart1.categories,
        },
      });

      setSeries1State(chartData.chart1.series);

      setOptions2State({
        ...chartDiagram3Option_chart2,
        xaxis: {
          categories: chartData.chart2.categories,
        },
      });

      setSeries2State(chartData.chart2.series);
    }
  }, [buttonIndex, buttonDateIndex, data]);

  return (
    <Styled.MainBox>
      <Styled.HeaderBox>
        <Styled.HeaderName>Offene Terminanfragen</Styled.HeaderName>
        <Styled.HeaderControl>
          {buttonDateIndex[buttonIndex] !== undefined && (
            <ChartDateButtons
              buttonDateIndex={buttonDateIndex[buttonIndex]}
              hanldeChangeDateIndex={hanldeChangeDateIndex}
            />
          )}
        </Styled.HeaderControl>
      </Styled.HeaderBox>

      {series1State && series1State.length > 0 && options1State && (
        <ReactApexChart
          options={options1State}
          series={series1State}
          type="bar"
          height={180}
        />
      )}

      <Styled.HeaderName>Aufschlüsselung nach Kreditoren</Styled.HeaderName>

      {series2State && series2State.length > 0 && options2State && (
        <ReactApexChart
          options={options2State}
          series={series2State}
          type="bar"
          height={series2State.length === 0 ? 100 : series2State.length * 150}
          width="100%"
        />
      )}
    </Styled.MainBox>
  );
};

export default Diagram3Chart;
