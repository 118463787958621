import React, {useState, useEffect} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import {DataContext} from "./context/context"
import Main from "./pages/main/main"
import "./app.less"
import {stateObj} from "./const/contexts"

const App = () => {
  const [state, setData] = useState(stateObj)

  const handlerSet = (newState) => {
    setData((statePrev) => ({...statePrev, ...newState}))
  }

  return (
    <DataContext.Provider value={{state, handlerSet}}>
    	<BrowserRouter>
    		<Routes>
					<Route path="/*" element={<Main/>} />
    		</Routes>
    	</BrowserRouter>
    </DataContext.Provider>
  )
}

export default App
