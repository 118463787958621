import React, { useEffect, useState } from "react";
import { Styled } from "./diagram2Chart.styled";
import ReactApexChart from "react-apexcharts";
import ChartDateButtons from "../../../common/ChartDateButtons";
import { arrButtonsName, chartDiagram2Option } from "../../../const/const";
import { convertDataToDayStepDiagram2 } from "../../../middleware/math";

const Diagram2Chart = ({
  buttonIndex,
  data,
  buttonDateIndex,
  hanldeChangeDateIndex,
}) => {
  useEffect(() => {
    if (
      data &&
      data[buttonIndex] &&
      buttonDateIndex[buttonIndex] !== undefined
    ) {
      setOptions1State({
        ...chartDiagram2Option,

        xaxis: {
          type: "datetime",
          categories: convertDataToDayStepDiagram2(
            data[buttonIndex],
            arrButtonsName[buttonDateIndex[buttonIndex]]
          ).categories,
        },
      });

      setSeries1State(
        convertDataToDayStepDiagram2(
          data[buttonIndex],
          arrButtonsName[buttonDateIndex[buttonIndex]]
        ).series
      );
    }
  }, [buttonIndex, buttonDateIndex[buttonIndex], data]);

  const [series1State, setSeries1State] = useState([]);

  const [options1State, setOptions1State] = useState({
    ...chartDiagram2Option,

    xaxis: {
      type: "datetime",
      categories: [],
    },
  });

  return (
    <Styled.MainBox>
      <Styled.HeaderBox>
        <Styled.HeaderName>Offene Terminanfragen</Styled.HeaderName>
        <Styled.HeaderControl>
          <ChartDateButtons
            buttonDateIndex={buttonDateIndex[buttonIndex]}
            hanldeChangeDateIndex={hanldeChangeDateIndex}
          />
        </Styled.HeaderControl>
      </Styled.HeaderBox>

      {series1State && series1State.length > 0 && options1State && (
        <ReactApexChart
          options={options1State}
          series={series1State}
          type="bar"
          height={350}
        />
      )}
    </Styled.MainBox>
  );
};

export default Diagram2Chart;
