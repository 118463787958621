import React, { useState } from "react";
import { Styled } from "./diagram2.styled";

import DeliveryItem from "../../LeftSide/DeliveryItem";
import Diagram2Chart from "../../Charts/diagram2Chart/diagram2Chart.component";
import { arrButtonsName, headerDiagram2 } from "../../../const/const";
import { convertDataToDayStepDiagram2 } from "../../../middleware/math";

const Diagram2 = ({ data }) => {
  const [buttonIndex, setButtonIndex] = useState(0);
  const [buttonDateIndex, setButtonDateIndex] = useState({ 0: 0, 1: 0 });

  const hanldeChangeIndex = (index) => {
    setButtonIndex(index);
  };

  const hanldeChangeDateIndex = (index) => {
    setButtonDateIndex((prev) => ({ ...prev, [buttonIndex]: index }));
  };

  return (
    <Styled.MainBox>
      <Styled.LeftBox>
        {headerDiagram2.map((item, index) => (
          <DeliveryItem
            key={index}
            color={buttonIndex === index ? "yellow" : "white"}
            header={item.header}
            value={[
              {
                color: item.color1,
                value: convertDataToDayStepDiagram2(
                  data[index],
                  arrButtonsName[buttonDateIndex[index]]
                ).series[0].data.reduce((sum, i) => sum + i, 0),
              },
              {
                color: item.color2,
                value: convertDataToDayStepDiagram2(
                  data[index],
                  arrButtonsName[buttonDateIndex[index]]
                ).series[1].data.reduce((sum, i) => sum + i, 0),
              },
              {
                color: item.color3,
                value: convertDataToDayStepDiagram2(
                  data[index],
                  arrButtonsName[buttonDateIndex[index]]
                ).series[2].data.reduce((sum, i) => sum + i, 0),
              },
            ]}
            hanldeChangeIndex={hanldeChangeIndex}
            index={index}
          />
        ))}
      </Styled.LeftBox>
      <Styled.RightBox>
        <Diagram2Chart
          buttonIndex={buttonIndex}
          data={data}
          buttonDateIndex={buttonDateIndex}
          hanldeChangeDateIndex={hanldeChangeDateIndex}
        />
      </Styled.RightBox>
    </Styled.MainBox>
  );
};

export default Diagram2;
