import React from "react";

import { Styled } from "./NumberOfDeliveryDateRequests.styled";

import split1 from "../../../images/split1.png";
import split2 from "../../../images/split2.png";

const NumberOfDeliveryDateRequests = ({
  color,
  header,
  value,
  hanldeChangeIndex,
  index,
  selectPeriod,
}) => {
  return (
    <Styled.MainBox
      $color={color === "yellow"}
      onClick={() => hanldeChangeIndex(index)}
    >
      <Styled.InsideBox>
        <Styled.HeaderBox $color={color === "yellow"}>
          {header}
        </Styled.HeaderBox>
        <Styled.GroupBox>
          <Styled.GroupItemLeft>
            <Styled.ItemBox>
              <Styled.ItemBoxIcon1>{selectPeriod}</Styled.ItemBoxIcon1>
              <Styled.ItemValue $color={color === "yellow"}>
                {value}
              </Styled.ItemValue>
            </Styled.ItemBox>
          </Styled.GroupItemLeft>
          <Styled.GroupItemRight>
            {color === "yellow" ? (
              <Styled.ItemBoxIcon2 src={split1} />
            ) : (
              <Styled.ItemBoxIcon2_2 src={split2} />
            )}
          </Styled.GroupItemRight>
        </Styled.GroupBox>
      </Styled.InsideBox>
    </Styled.MainBox>
  );
};

export default NumberOfDeliveryDateRequests;
