import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Styled } from "./main.styled";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Dashboard from "../dashboard";
import useAuth from "../../auth/useAuth";

function Main() {
  // const [pending, islogIn] = useAuth();

  return (
    <Styled.MainBox>
      <Header />
      <Styled.Content>
        <Dashboard />
      </Styled.Content>
      <Footer />
    </Styled.MainBox>
  );
}
export default Main;
