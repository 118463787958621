import React from "react";

import { Styled } from "./DeliveryItem.styled";
import fast_delivery_1 from "../../../images/fast-delivery_1.png";
import lieferzeit1 from "../../../images/lieferzeit1.png";
import lieferzeit2 from "../../../images/lieferzeit1_2.png";

const DeliveryItem = ({ color, header, value, hanldeChangeIndex, index }) => {
  return (
    <Styled.MainBox
      $color={color === "yellow"}
      onClick={() => hanldeChangeIndex(index)}
    >
      <Styled.InsideBox>
        <Styled.HeaderBox $color={color === "yellow"}>
          {header}
        </Styled.HeaderBox>
        <Styled.GroupBox>
          <Styled.GroupItemLeft>
            {value.map((item, index) => (
              <Styled.ItemBox key={index}>
                <Styled.ItemBoxIcon1
                  color={color === "yellow" ? item.color[0] : item.color[1]}
                ></Styled.ItemBoxIcon1>
                <Styled.ItemValue>{item.value}</Styled.ItemValue>
              </Styled.ItemBox>
            ))}
          </Styled.GroupItemLeft>
          <Styled.GroupItemRight>
            {index === 0 ? (
              color === "yellow" ? (
                <Styled.ItemBoxIcon2 src={lieferzeit1} />
              ) : (
                <Styled.ItemBoxIcon2fast src={lieferzeit2} />
              )
            ) : (
              <Styled.ItemBoxIcon2fast src={fast_delivery_1} />
            )}
          </Styled.GroupItemRight>
        </Styled.GroupBox>
      </Styled.InsideBox>
    </Styled.MainBox>
  );
};

export default DeliveryItem;
